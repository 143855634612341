import React from 'react';

import PalmyraGridX from '../../../palmyra/widget/datagrid/PalmyraGridX';

const columnDef = [
    {
        name: "tehsilName",
        label: "Name",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
    {
        name: "tehsilCode",
        label: "Taluk Code",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
    {
        name: "districtName",
        label: "District",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    }
];


const TalukPage = () => {
    return (
        <div>
            Taluk
            <PalmyraGridX columns={columnDef} target="admin/masterdata/tehsil" />
        </div>
    )
}
export default TalukPage;

function getRandom(baseline, variance) {
    return Math.floor(Math.random() * variance) + baseline;
}

class RandomBubbleGenerator {
    getRandomArray() {
        var data = [];
        for (var i = 0; i < 64; i++) {
            data[i] = this.getRandom(1024, 1024, 48);
        }
        return data;
    }
    getRandomYArray() {
        var data = [];
        for (var i = 0; i < 64; i++) {
            var x = getRandom(1, 1024);
            var y = getRandom(1, 1024);
            var r = getRandom(1, 48);
            var p = { x: x, y: y, r: r };
            data[i] = p;
        }
        return data;
    }
    getRandomRArray() {
        var data = [];
        for (var i = 0; i < 64; i++) {
            var r = getRandom(1, 48);
            var p = { x: "", y: "", r: r };
            data[i] = p;
        }
        return data;
    }
}

const randomBubbleGenerator = new RandomBubbleGenerator();

export default randomBubbleGenerator;
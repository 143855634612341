class BubbleChartConverter {

    constructor(props) {
        if (props) {
            this.x = props.xName || "x";
            this.y = props.yName || "y";
            this.r = props.rName || "r";
        } else {
            this.x = "x";
            this.y = "y";
            this.r = "r";
        }
    }

    /**
     * 
     * @param  rawData 
     * @returns array of labels and array of processed Data
     */
    convert(rawData) {
        var x = this.x;
        var y = this.y;
        var r = this.r;
        var labels = [];
        var data = [];
        for (var idx in rawData) {
            var d = rawData[idx];
            data.push(d);
            labels.push('');
        }
        return { labels, data: [{ data }] };
    }
}

export default BubbleChartConverter;
/**
 * The entire dataset will be provided to this method and shall be processed
 */
class NoopsConverter {
    convert(rawData) {
        if (rawData instanceof Array)
            return { labels: [], data: rawData };
        else
            return { labels: [], data: [rawData] };
    }
}
export default NoopsConverter;

const getAgeLabel = function(dob) {
    var d2 = new Date();
    var d1 = new Date(dob);
    var months;
    var years = (d2.getFullYear() - d1.getFullYear());
    var months = d2.getMonth() - d1.getMonth();
    if (months < 0) {
        months = months + 12;
        years--;
    }
    var label = '';
    if (years > 0) {
        label += years + "y";
    }
    if (months > 0) {
        if (label.length > 0)
            label += ",";
        label += months + "m";
    }
    return label;
}

const getMonthsDiff = function(dob, measuredOn){        
    var d2 = new Date(measuredOn);
    var d1 = new Date(dob);        
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months;
}

const getAgeLabelByMonth = function(months) {    
    var months;
    var years = Math.floor(months / 12);
    var months = months % 12;
    
    var label = '';
    if (years > 0) {
        label += years + "y";
    }
    if (months > 0) {
        if (label.length > 0)
            label += ",";
        label += months + "m";
    }
    return label;
}

export {
    getAgeLabel,
    getAgeLabelByMonth, 
    getMonthsDiff
}
import barGenerator from "../util/RandomBarGenerator";

class RandomBarDataStore {
    getData(count, callback) {
        var data = barGenerator.getRandomYArray(0, 120, count);
        callback(data);
    }
}

const barDataStore = new RandomBarDataStore();

export default barDataStore;
import './HomePage.scss';
import { createElement, createRef, useRef, useState } from 'react';

import ChildGridRenderer from "../components/child/ChildGridRenderer";
import PalmyraGrid from "../palmyra/widget/grid/PalmyraGrid";
import SimpleSearch from '../palmyra/widget/grid/filter/SimpleSearch';
import ServerLookup from '../palmyra/widget/form/ServerLookup';
import { TablePagination } from '@mui/material';
import ComboBox from '../palmyra/widget/form/ComboBox';
import {BiDotsVerticalRounded} from 'react-icons/bi';

function HomePage() {
    let grid = useRef();
    const [pageData, setPageData] = useState({
        pageSize:12,currentPage:1, totalRecords:0
    });

    let onPrev = () => { grid.current.gotoPrevPage() };
    let onNext = () => { grid.current.gotoNextPage() };
    let gotoPage = (event, p) => {grid.current.gotoPage(p) };

    let applyCriteria = (key, value) => { grid.current.applyCriteria(key, value) };
    let showFilter = () => { console.log("show Filter") };

    // let paginationElement = createElement(SimplePagination, { gotoPrev: onPrev, gotoNext: onNext, pageData });
    var currentPage = (pageData.totalRecords>0) ? pageData.currentPage : 1;
    let paginationElement = createElement(TablePagination, {component:"div",
    sx:{width:250},    count:pageData.totalRecords,
    onPageChange:gotoPage,
    page:(currentPage-1),
    rowsPerPageOptions:[],
    rowsPerPage:pageData.pageSize});

    let initialPage = {currentPage :0, pageSize:12, totalRecords: 0};

    let searchElement = createElement(SimpleSearch, { applyFilter: (f) => applyCriteria('name', f)});
    const width = 200;
    let interventionOptions = [
        { label: 'Medicine', id: 'M' },
        { label: 'Diet', id: 'D' },
        { label: 'Surgery', id: 'S' },
      ];

    //   function toggleContent() {
    //     const content = document.getElementById('content');
    //     const button = document.querySelector('.show-content-button');
    //     button.classList.toggle('clicked');
    //   }

    return (
        <div>
            
            <div className="grid-header" >
            {/* <div className="show-content-button" onClick={() => toggleContent()}>
                <BiDotsVerticalRounded/>
                </div> */}
        {/* <div className="content-container" id="content"> */}
                <div className="grid-header-filter" >
                    <ComboBox
                    value="label"
                    onChange={(val) => {
                        if (val) applyCriteria('intervention', { interventionType: val.id, status:"UT" });
                        else
                            applyCriteria('intervention');
                    }}
                    options={interventionOptions}
                    width={width}
                    placeHolder="Intervention"
                    />
                </div> 
                 <div className="grid-header-filter">
                    <ServerLookup placeHolder="All Projects"
                    width={width}
                        value="projectName"
                        onChange={(val) => {
                            if (val) applyCriteria('project', { project: val.id });
                            else
                                applyCriteria('project');
                        }}
                        targetUrl="admin/masterdata/project" />
                </div>
                <div className="grid-header-pagination">
                    {searchElement}
                </div>
             {/* </div>    */}
                <div className="grid-header-pagination1">
                    {paginationElement}
                </div>
            </div>
                <PalmyraGrid renderer={ChildGridRenderer} ref={grid} targetUrl="child/basic"
                    pageData={initialPage}
                    onPageChange={(pageData) => setPageData(pageData)}
                    className="ChildCardLayout" records={[]} />

        </div>
    );
}
export default HomePage;
import React, { useState, createElement, useEffect } from "react";
import PluggableRenderer from "./PluggableRenderer";
import('./grid.css')

const SimpleGrid = (props) => {
    const [records, setRecords] = useState([]);
    var r = props.records;
    var rowRenderer = props.rowRenderer;
    var gridRenderer = props.gridRenderer || PluggableRenderer;
    
    useEffect(() =>{
        
    }, [records])
    
    var grid = createElement(gridRenderer, { className: props.className, renderer: rowRenderer, records:r, key:Math.random()});

    return (
        <div className="grid-content">
            {grid}
        </div>
    );
}

export default SimpleGrid;
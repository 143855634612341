
import InterventionChart from '../../components/DashBoard/InterventionChart';
import StatusChart from '../../components/DashBoard/StatusChart';
import GenderChart from '../../components/DashBoard/GenderChart';
import PovertylineChart from '../../components/DashBoard/PovertylineChart';
import CasteChart from '../../components/DashBoard/CasteChart';
import GrowthHistoryEntryChart from '../../components/DashBoard/GrowthHistoryEntryChart';
import './DashboardPage.css'

const DashboardPage = () => {
    return (
          <div>        
            <div className='dashboardContainer'>
                <div className='dashboardChart'><StatusChart  /></div>
                <div className='dashboardChart'><InterventionChart /></div>
                <div className='dashboardChart'><GenderChart /></div>
             
                <div className='dashboardChart'><PovertylineChart /></div>
                <div className='dashboardChart'><CasteChart /></div>
                <div className='dashboardChart'><GrowthHistoryEntryChart /></div>
            </div>
    </div>  
    );
};

export default DashboardPage;
import { DarkModeContext } from '../../context/darkModeContext';
import { useContext } from 'react';
const LoginHeader=()=>{
    const {darkMode}= useContext(DarkModeContext);
    return(
        <div className={darkMode?"loginheaderdark":'loginheader'}>
            <h2>PoshaQ Tracker</h2>
        </div>
    )
}
export default LoginHeader;

import { Route } from "react-router-dom";
import PageWrapper from "../layout/PageWrapper";
import appRoutes from "./appRoutes";

const generateRoute = (routes, parentPath) => {
  return routes.map((route, index) => {
    let path = (parentPath) ? parentPath + "/" + route.path : route.path;    
    return route.index ? (
      <Route
        index
        path={path}
        element={<PageWrapper state={route.state}>
          {route.element}
        </PageWrapper>}
        key={index}
      />
    ) : (
      <Route
        path={route.path}
        element={
          // <PageWrapper state={route.children ? undefined : route.state}>
            route.element
          // </PageWrapper>
        }
        key={path}
      >
        {route.children && (
          generateRoute(route.children, path)
        )}
      </Route>
    )
        });
};

export const routes = generateRoute(appRoutes);
import AbstractRenderer from "./AbstractRenderer";


class PluggableRenderer extends AbstractRenderer {

    constructor(props) {
        super(props);        
        this.renderer = props.renderer;
    }

    renderRecord(record, idx){
        if(this.renderer){
            let Renderer = this.renderer;
            return <Renderer record={record} key={record.id} index={idx}/>
        }else{
            return <div id={idx}>{idx}</div>
        }
    }
}
export default PluggableRenderer;
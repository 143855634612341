import growthMetricFactoryWeight from "./growthDataWeight/GrowthMetricFactoryWeight";
import growthMetricFactoryHeight from "./growthDataHeight/GrowthMetricFactoryHeight";

class GrowthHistoryConverter {

    constructor(props) {
        this.child = props.child;
        this.x = props.xName || "measuredOn";        
        this.defaultLabel = props.defaultLabel || "NA";        
    }

    /**
     * 
     * @param  rawData 
     * @returns array of labels and array of processed Data
     */
    convert(rawData) {
        var x = this.x;
        var y = this.y;
        var dob = this.child.dob;
        var stdData = this.getStdData();
        var data = [];
        for (var idx in rawData) {
            var d = rawData[idx];            
            var idx = this.calcMonth(dob, d[x]);
            data[idx]=d[y];
        }
        let datasets = [];
        
        for(var i in stdData.data){
            let data = stdData.data[i];
            datasets.push({data});
        }
        
        datasets.push({data});
        
        var result = { labels : stdData.labels, datasets };
        return result;
    }

    getStdData(){
        return {};
    }

    calcMonth(dob, measuredOn){        
        var d2 = new Date(measuredOn);
        var d1 = new Date(dob);        
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }
}

class GrowthHistoryWeightConverter extends GrowthHistoryConverter{

    constructor(props) {
        super(props)        
        this.y = "weight"; // or height        
    }

    getStdData(){        
        let max = this.calcMonth(this.child.dob, new Date());
        return growthMetricFactoryWeight.getMetric(this.child, 0, max);        
    }
}


class GrowthHistoryHeightConverter extends GrowthHistoryConverter{

    constructor(props) {
        super(props)        
        this.y = "height";
    }

    getStdData(){        
        let max = this.calcMonth(this.child.dob, new Date());
        var result = growthMetricFactoryHeight.getMetric(this.child, 0, max);        
        console.log(result);
        return result;
    }
}


export {GrowthHistoryWeightConverter, GrowthHistoryHeightConverter};
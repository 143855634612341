/**
 * The entire dataset will be provided to this method and shall be processed
 */
class LineChartConverter {

    constructor(props) {
        if (props) {
            this.x = props.xName || "x";
            this.y = props.yName || "y";
        } else {
            this.x = "x";
            this.y = "y";
        }
    }

    /**
     * 
     * @param  rawData 
     * @returns array of labels and array of processed Data
     */
    convert(rawData) {
        var x = this.x;
        var y = this.y;
        var labels = [];
        var data = [];
        for (var idx in rawData) {
            var d = rawData[idx];
            data.push(d[y]);
            labels.push(d[x]);
        }
        return { labels, data: [{ data }] };
    }
}

export default LineChartConverter;

class PointConverter {

    constructor(props) {
        if (props) {
            this.x = props.xName || "name";
            this.y = props.yName || "count";
            this.defaultLabel = props.defaultLabel || "NA";
        } else {
            this.x = "name";
            this.y = "count";
            this.defaultLabel = "NA";
        }
    }

    /**
     * 
     * @param  rawData 
     * @returns array of labels and array of processed Data
     */
    convert(rawData) {
        var x = this.x;
        var y = this.y;
        var labels = [];
        var data = [];
        for (var idx in rawData) {
            var d = rawData[idx];
            data.push(d[y]);
            if (d[x])
                labels.push(d[x]);
            else
                labels.push(this.defaultLabel);
        }
        return { labels, datasets: [{ data }] };
    }
}

export default PointConverter;
import { createContext, useState} from "react"
import { useWindowSize } from "usehooks-ts";

const INITIAL_STATE={
    mobileMode: false, 
    mobileOpen:false    
}

export const MobileContext = createContext(INITIAL_STATE );

export const MobileModeContextProvider = ({children})=>{

    const [mobileMode, setMobileMode] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
   

   return(
    <MobileContext.Provider  value={{ mobileMode, setMobileMode, mobileOpen, setMobileOpen }} >
        {children}
    </MobileContext.Provider>
   )
};

export const MobileMode = MobileContext.Consumer

/**
 * the data should have
 * currentPage, pageSize, totalRecords
 * @param {*} data 
 * @returns 
 */
function getDisplayLabels(data) {    
    var recordStart = (data.currentPage - 1) * data.pageSize + 1;
    var recordEnd = data.currentPage * data.pageSize;
    if(recordEnd > data.totalRecords)
        recordEnd = data.totalRecords;
    var totalPages = data.totalRecords / data.pageSize;
    return {
        recordStart:recordStart,
        recordEnd:recordEnd,
        totalRecords:data.totalRecords, 
        pageSize:data.pageSize, 
        totalPages:totalPages,
        currentPage:data.currentPage
    }
}

function calcPage(pageData){
    var pageSize = pageData.limit;    
    var totalRecords = pageData.total;
    
    var currentPage = (pageData.offset / pageSize) + 1
    var recordEnd = currentPage * pageSize;    
    var recordStart = (currentPage - 1) * pageSize + 1;    

    if(recordEnd > totalRecords)
        recordEnd = totalRecords;
    var totalPages = Math.ceil(totalRecords / pageSize);
    
    return {
        recordStart:recordStart,
        recordEnd:recordEnd,
        totalRecords:totalRecords, 
        pageSize:pageSize, 
        totalPages:totalPages,
        currentPage:currentPage
    }
}

function getPrevPage(pagination){    
    if(pagination.currentPage > 1){            
        let offset = (pagination.currentPage-2) * pagination.pageSize;
        return {limit:pagination.pageSize,offset:offset, prevPage:(pagination.currentPage-1)};
    }
}

function getNextPage(pagination){
    let offset = pagination.currentPage * pagination.pageSize;
    if(offset < pagination.totalRecords){        
        return {limit:pagination.pageSize,offset:offset,nextPage:(pagination.currentPage+1)};
    }
}


export {getDisplayLabels, getPrevPage, getNextPage, calcPage};
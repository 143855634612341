import React, { useEffect, createElement, useState} from 'react'
import { TablePagination, TextField } from '@mui/material';
import { getNextPage, getPrevPage, calcPage } from "../grid/pagination/PaginationUtils";
import { generateColumns } from './ColumnConverter';
import TableX from "./TableX";
import { InputAdornment } from "@mui/material";
import  Button  from '@mui/material/Button';
import '../../widget/datagrid/Grid.scss';
import {BiFilter} from  'react-icons/bi';
import { AiOutlineSearch } from 'react-icons/ai';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import MenuIcon from '@mui/icons-material/Menu';
import DensitySmallIcon from '@mui/icons-material/DensitySmall';
import DensityLargeIcon from '@mui/icons-material/DensityLarge';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';



function Gridx(props,options) {
  const { columns, store } = props;
  const [pageData, setPageData] = useState({
    pageSize: 12, currentPage: 1, totalRecords: 0
  });
  const [query, setQuery] = useState({
    total:true
  });
  const [data, setData] = useState(() => []);

  useEffect(() => {
    refreshData(query);
  }, [query])

  const columnDefs = generateColumns(columns);

  const refreshData = (options) => {    
    store.query(options, (d) => {
      setData(d.result);
      if (!options.total) {
        d.total = pageData.totalRecords;
      }
      if (options.limit)
        d.limit = options.limit;
      setPageData(calcPage(d));
    });
  }

  const gotoPage = (e, pageNumber) => {
    var curPage = pageData.currentPage - 1;
    let nextPage = {};
    if (curPage < pageNumber) {
      nextPage = getNextPage(pageData);
    } else if (curPage > pageNumber) {
      nextPage = getPrevPage(pageData);
    }
    setQuery({ offset: nextPage.offset, limit: nextPage.limit })
  };




  var currentPage = (pageData.totalRecords > 0) ? pageData.currentPage : 1;

  let paginationElement = createElement(TablePagination, {
    component: "div",
    sx: { width: 250 }, count: pageData.totalRecords,
    onPageChange: gotoPage,
    page: (currentPage - 1),
    rowsPerPageOptions: [],
    rowsPerPage: pageData.pageSize
  });

  const [filter, setFilter] = useState('');
  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleFilterClick = () => {
    const filteredData = data.filter((item) =>
      item.name.toLowerCase().includes(filter.toLowerCase())
    );
    setData(filteredData);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    let handler = (event) => {
      if (!event.target.closest(".density-dropdown-content")) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);
  const [exportdropdownOpen, setExportDropdownOpen] = useState(false);
  

  const toggleExportDropdown = () => {
    setExportDropdownOpen(!exportdropdownOpen);
  };
  useEffect(() => {
    let handler = (event) => {
      if (!event.target.closest(".export-dropdown-content")) {
        setExportDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const [filterdropdownOpen, setFilterDropdownOpen] = useState(false);
  

  const toggleFilterDropdown = () => {
    setFilterDropdownOpen(!filterdropdownOpen);
  };
  useEffect(() => {
    let handler = (event) => {
      if (!event.target.closest(".filter-dropdown-content")) {
        setFilterDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);
  const width = props.width || 200;
 

  return (
    <div>
        <div className='gridheader-filter'>
      
      <div className='grid-filter'>
      <TextField
         sx={{ width: width }}
         type="text"
         value={filter}
         onChange={handleFilterChange}
         style={{ border: "0px"}}
         size="small"
         placeholder="Name"
         InputProps={{
             endAdornment: (
               <InputAdornment position="end" >
                   <AiOutlineSearch className="grid-filter-icons" onClick={handleFilterClick}/>
               </InputAdornment>
             ),
           }}
      />
      </div>
      <div className='grid-button' onClick={toggleDropdown}>
          <Button className='grid-density-btn' disableRipple>
            <MenuIcon className='grid-density-button-icon'/>
          </Button>
          <span className='density-text'>Density</span>
        {dropdownOpen && (
          <div className="density-dropdown-content">
            <ul>
              <li>
                <DensitySmallIcon/>
                <span className='drodown-content-text'>Compact</span>
              </li>
              <li>
                <MenuIcon/>
                <span className='drodown-content-text'>Standard</span>
              </li>
              <li>
                <DensityLargeIcon/>
                <span className='drodown-content-text'>Comfortable</span>
              </li>
            </ul>
          </div>
        )}
        </div>

        <div className='grid-button' onClick={toggleFilterDropdown}>
          <Button className='grid-filter-btn' disableRipple>
            <FilterAltIcon className='grid-button-icon'/>
          </Button>
          <span className='filter-text'>Filter</span>
        {filterdropdownOpen && (
          <div className="filter-dropdown-content">
            <ul>
              <li>
                <span className='drodown-content-text'>Name</span>
              </li>
              <li>
                <span className='drodown-content-text'>Id</span>
              </li>
             </ul> 
          </div>
        )}
        </div>

        <div className='grid-button' onClick={toggleExportDropdown}>
          <Button className='grid-density-btn' disableRipple>
            <FileDownloadOutlinedIcon/>
          </Button>
          <span className='export-text'>Export</span>
        {exportdropdownOpen && (
          <div className="export-dropdown-content">
            <ul>
              <li>
                <span className='drodown-content-text'>Download as CSV</span>
              </li>
              <li>
                <span className='drodown-content-text'>Print</span>
              </li>
             </ul> 
          </div>
        )}
        </div>

      <div className='grid-filter'>
      {paginationElement}
      </div>
      
      </div>
      <TableX  columnDefs={columnDefs} rowData={data} 
      />
     

    </div>
  )
}

export default Gridx;
import React from "react";
import ChildCard from "./ChildCard";

class ChildGridRenderer extends React.Component{
    constructor(props){
        super(props);
        this.state={
            data:props.record
        };
    }

    render(){
        var data = this.state.data || {};
        return <div className="ChildCardView"> <ChildCard data={data} /> </div>;
    }
}

export default ChildGridRenderer;
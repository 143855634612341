import React from "react";

class AbstractRenderer extends React.Component {

    constructor(props) {
        super(props);
        var records = props.records || [];
        this.columns = props.columns || {};
        this.className = props.className || {};
        this.state = {
            records: records
        };
    }

    renderRecord(record, idx){
        return <div>idx</div>;
    }

    getColumns(){
        return this.columns;
    }

    getRecords(){
        return this.state.records;
    }

    renderRecords(records){
        const rows = [];
        for (var idx in records) {
            rows.push(this.renderRecord(records[idx], idx));
        }
        return rows;
    }

    render() {
        const rows = this.renderRecords(this.getRecords());
        return (
            <div className={this.className}>
                {rows}
            </div>
        );
    }
}
export default AbstractRenderer;
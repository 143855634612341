import './login.css'
import "../components/DarkStyle/dark.css";
import LoginBarChart from '../components/login/LoginBarChart';
import { MdBedtime } from "react-icons/md";
import LoginForm from '../components/login/LoginForm';
import LoginLineChart from '../components/login/LoginLineChart';
import LoginBubbleChart from '../components/login/LoginBubbleChart';
import { useContext } from 'react';
import { DarkModeContext } from '../components/context/darkModeContext';

const LoginPage = () => {
    const { darkMode, dispatch } = useContext(DarkModeContext);

    return (
        <div className={darkMode ? "loginFramedark" : 'loginFrame'}>
            <div className={darkMode ? "loginbarchartdark" : 'loginBarChartContainer'}>
                <LoginBarChart />
            </div>
            <div className={darkMode ? "loginlinechartdark" : 'loginlineChartContainer'}>
                <LoginLineChart />
            </div>

            <div className={darkMode ? "loginformdark" : 'loginFormContainer'}>
                <LoginForm />
            </div>
            <div className='darkmode'>
                <MdBedtime onClick={() => dispatch({ type: "TOGGLE" })} />
            </div>
            <div className='loginBubbleChartContainer'>
                <LoginBubbleChart />
            </div>

        </div>
    )
}


export default LoginPage;
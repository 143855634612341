import { Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import React, { useState } from 'react';
import "./childDetail.css";
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Textarea from '@mui/joy/Textarea';

const ChildDetailCommentPopup = ({ isOpen, onClose }) => {
    const [comment, setComment] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onClose();
    };

    return (
        <div>
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle>Remarks:</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit}>
                        <Textarea
                            maxRows={6}
                            minRows={6}
                            variant="outlined"
                            name="comment"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            style={{ width: '40ch' }}
                        /><br />
                        <p className="close-icon" onClick={onClose}><CloseIcon /></p>
                        <div style={{ width: '100%', textAlign: 'right' }}>
                            <Button className="comment-submitbutton"
                                style={{ backgroundColor: 'rgba(56, 149, 131, 0.9)', color: 'white' }}
                                onClick={onClose}>Submit</Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ChildDetailCommentPopup;
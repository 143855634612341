import React from "react";
import PalmyraBarChart from "../../palmyra/chart/PalmyraBarChart";
import { DarkModeContext } from '../context/darkModeContext';
import barDataStore from "../../palmyra/chart/store/RandomBarDataStore"
import BarChartConverter from "../../palmyra/chart/converter/BarChartConverter"
import SimpleDecorator from "../../palmyra/chart/decorator/SimpleDecorator";

const config = {
    autoRefresh:16,
    maintainAspectRatio: false,
    responsive: true,
    animation: {
        duration: 15000,
        easing: 'linear'
    },
    scales: {
        x: {
            beginAtZero: true,
            display: false,
        },
        y: {
            reverse: true,
            display: false,
            beginAtZero: true,
            ticks: {
                beginAtZero: true
            }
        }
    },
    plugins: {
        tooltip: {
            enabled: false
        },
        datalabels: {
            display: false
        },
        legend: {
            display: false
        }
    },
    elements: {
        point: {
            radius: 0
        }
    }
};

class LoginBarChart extends React.Component {
    static contextType = DarkModeContext;

    render() {
        const darkMode = this.context.darkMode;
        var bgColor = darkMode ? 'rgba(15,14,14,0.075)' : 'rgba(56, 49, 131, 0.02)';
        var borderColor = darkMode ? 'rgba(6, 9, 31, 0.1)' : 'rgba(56, 49, 131, 0.01)';
        var barPercentage = darkMode ? 1 : 1.5;
        var borderWidth = darkMode ? 0.1 : 0;


        var decorator = new SimpleDecorator({
            label: '',
            beginAtZero: true,
            fill: true,
            barPercentage: barPercentage,
            categoryPercentage: 1,
            backgroundColor: bgColor,
            borderColor: borderColor,
            opacity: 0.01,
            borderWidth: borderWidth
        });

        var converter = new BarChartConverter({ yName: "y" });

        return (
            <div style={{ height: '100%', width: '100%' }}>
                <PalmyraBarChart options={config} dataStore={barDataStore}
                    converter={converter} decorator={decorator} />
            </div>
        );
    }
}

export default LoginBarChart;
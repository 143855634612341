import { Navigate, Outlet } from "react-router-dom";
import HomePage from '../../pages/HomePage';

import DashboardPage from '../../pages/dashboard/DashboardPage';
import ChildDetailPage from '../../pages/child/ChildDetailPage'

import AnganwadiPage from "../../pages/admin/masterdata/AnganwadiPage";
import HospitalPage from "../../pages/admin/masterdata/HospitalPage";
import BlockPage from "../../pages/admin/masterdata/BlockPage";
import DistrictPage from "../../pages/admin/masterdata/DistrictPage";
import StatePage from "../../pages/admin/masterdata/StatePage";
import TalukPage from "../../pages/admin/masterdata/TalukPage";

import GroupsPage from "../../pages/admin/usermgmt/GroupsPage";
import UsersPage from "../../pages/admin/usermgmt/UsersPage";
import GridPage from "../../pages/dev/GridPage";

import EditChildDetailPage from "../../pages/child/EditChildDetailPage";

const appRoutes = [
  {
    index: true,
    element: <Navigate to="default" replace={true} />,
    state: "home"
  },
  {
    path: "default",
    name: "Home",
    element: <HomePage />,
    state: "home"
  },
  // {
  //   path: "grid",
  //   name: "Test Grid",
  //   element: <GridPage />,
  //   state: "gridPage"
  // },
  {
    path: "child/:childId",
    element: <ChildDetailPage />,
    state: "childDetail"
  },
  {
    path: "child/:childId/edit",
    element: <EditChildDetailPage />,
    state: "editchilddetail",
  },
  {
    path: "dashboard",
    element: <DashboardPage />,
    state: "dashboard",
    name: "Dashboard"
  },
  {
    path: "admin",
    element: <Outlet />,
    name: "Administration",
    state: "admin",
    children: [
      // {
      //   path: "usermgmt",
      //   element: <Outlet />,
      //   name: "User Management",
      //   state: "usermgmt",
      //   children: [
      //     {
      //       path: "group",
      //       element: <GroupsPage />,
      //       name: "Groups",
      //       state: "group",
      //     },
      //     {
      //       path: "user",
      //       element: <UsersPage />,
      //       name: "Users",
      //       state: "user",
      //     }
      //   ],
      // },
      {
        path: "masterdata",
        element: <Outlet />,
        name: "Master Data",
        state: "admin_masterdata",

        children: [
          {
            path: "anganwadi",
            element: <AnganwadiPage />,
            name: "Anganwadi",
            state: "admin_usermgmt",
          },
          {
            path: "hospital",
            element: <HospitalPage />,
            name: "Hospital",
            state: "admin_usermgmt",
          },
          {
            path: "block",
            element: <BlockPage />,
            name: "Block",
            state: "admin_usermgmt",
          },
          // {
          //   path: "district",
          //   element: <DistrictPage />,
          //   name: "District",
          //   state: "admin_usermgmt",
          // },
          {
            path: "taluk",
            element: <TalukPage />,
            name: "Taluk",
            state: "admin_usermgmt",
          }
        ],
      },
    ]
  }
];

export default appRoutes;
import React, { createElement } from "react";
import ReactDOM from 'react-dom/client';
import { Link } from "react-router-dom";
import GrowthChartWeight, { GrowthChartDecoratorWeight } from "./GrowthChartWeight";
import PalmyraDataStore from "../../palmyra/chart/store/palmyra/PalmyraDataStore";
import { getAgeLabel, getAgeLabelByMonth } from "./Util";
import { GrowthHistoryWeightConverter } from "../converter/GrowthHistoryConverter";
import GrowthChartDialog from "./GrowthChartDialog";
import '../../pages/HomePage.scss';
import "./ChildCard.scss"
import BorderColorIcon from '@mui/icons-material/BorderColor';
import ViewDayIcon from '@mui/icons-material/ViewDay';



class ChildCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            growthHistory: [],
            dialogOpen: false,
            showEdit: false,
            hideText: false,
        }
        this.handleChartClick = this.handleChartClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleMouseOver = () => {
        this.setState({ showEdit: true });
        this.setState({ hideText: true });
    }

    handleMouseOut = () => {
        this.setState({ showEdit: false });
        this.setState({ hideText: false });
    }

    handleChartClick() {
        this.el = document.createElement("div");
        this.dialogRoot = ReactDOM.createRoot(this.el);
        this.dialogRoot.render(<GrowthChartDialog child={this.state.data} onClose={() => this.handleClose()} />);
    }

    handleClose() {
        if (this.el) {
            this.dialogRoot.unmount();
            this.el.remove();
            delete this.el;
        }
    }

    getStatus(child) {
        switch (child.status) {
            case 'EX':
                return 'Expired';
            case 'MA':
                return 'Moved Away';
            case 'RC':
                return 'Recovered';
            case 'UT':
            default: {
                switch (child.interventionType) {
                    case 'S':
                        return 'Surgery';
                    case 'M':
                        return 'Medicine';
                    case 'D':
                        return 'Diet';
                    default:
                        return 'Not Classified';
                }
            }
        }
    }

    render() {
        var config = {
            maintainAspectRatio: false,
            plugins: {
                title: {
                    display: false,
                },
                datalabels:
                {
                    display: false
                },
                legend: {
                    display: false,
                    position: "top"
                },
                tooltip: {
                    callbacks: {
                        title: function (ctx) {
                            let context = ctx[0];
                            return getAgeLabelByMonth(Number(context.label)) + "\n" + context.raw + "kg";
                        },
                        label: function (context) {
                            return '';
                        }
                    }
                }
            }, scales: {
                x: {
                    type: 'linear',
                    beginAtZero: true,
                    ticks: {
                        mirror: true,
                        color: '#aaaaaa',
                        maxTicksLimit: 8
                    },
                    grid: {
                        color: "#f9f9f9"
                    }
                },
                y: {
                    type: 'linear',
                    beginAtZero: true,
                    ticks: {
                        mirror: true,
                        color: '#aaaaaa',
                        maxTicksLimit: 8
                    },
                    grid: {
                        color: "#f9f9f9"
                    }
                }
            }
        };

        var decorator = new GrowthChartDecoratorWeight({
            borderColor: 'rgba(19,19,15,0.6)',
            borderWidth: 2,
            spanGaps: true
        });
        const orderBy = ["measuredOn"];
        var child = this.state.data || {};
        const filter = { criteria: { childId: child.id }, orderBy };
        let dataStore = new PalmyraDataStore({ target: "child/growthHistory", filter });
        var converter = new GrowthHistoryWeightConverter({ child, yName: "weight" });


        let age = getAgeLabel(child.dob);
        let status = this.getStatus(child);
        var headerClass = "child-cardview-header child-cardview-header-" + status.replace(/\s+/g, '').toLowerCase();

        const { hideText } = this.state;
        const childStatus = {
            visibility: hideText ? "hidden" : "visible"
        };

        return (
            <div className="child-cardview">
                <Link
                    to={"../child/" + child.id}>
                    <div className={headerClass} onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}>
                        <div className="child-cardview-name">
                            {child.name} /{child.gender} {age}</div>
                        <div className="child-cardview-status">
                            {this.state.showEdit &&
                                <div className="edit-view-button-padding">
                                    <Link to={"../child/" + child.id + "/edit"}>
                                        <div className="child-cardview-edit-button"><BorderColorIcon style={{ fontSize: 10 }} /> Edit</div>
                                    </Link>
                                    <div className="child-cardview-view-button"><ViewDayIcon style={{ fontSize: 10 }} /> View</div>
                                </div>
                            }
                            <div style={childStatus}>
                                {status}
                            </div>
                        </div>
                    </div>
                </Link >
                <div className="child-cardview-content">
                    <div className="child-cardview-left">
                        {child.fatherName} <br />
                        {child.motherName} <br />
                        {child.contactMobile} <br />
                        <br />
                        {child.anganwadiName}
                    </div>
                    <div className="child-cardview-right" onClick={this.handleChartClick}>
                        <GrowthChartWeight style={{ height: "100%", width: "100%" }} options={config} dataStore={dataStore}
                            converter={converter} decorator={decorator} child={child} />
                    </div>
                </div>
            </div >
        );
    }
}
export default ChildCard;
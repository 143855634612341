import { debounce } from "../../../common/SysUtils";

class ServerFilter {

    constructor(props) {        
        this.criteriaList = {};
        if(props && props.applyCriteria){
            this.applyCriteria = debounce(props.applyCriteria, 300);
        }
    }

    addCriteria(key, criteria){
        if(criteria){
            this.criteriaList[key] = criteria;
        }else{
            this.criteriaList[key] = {};
        }

        if(this.applyCriteria){            
            this.applyCriteria(this.getCriteria());
        }
    }

    getCriteria(){
        let f = {};
        for(let idx in this.criteriaList){
            f = {...f, ...this.criteriaList[idx]};
        }
        return f; 
    }
}

export default ServerFilter;
import { useState, useEffect } from "react";
import { getAgeLabel } from "../child/Util";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import './childDetail.css';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Link } from "react-router-dom";

function ChildHeader(props) {
    let childData = props.childData;

    const [child, setChild] = useState({});
    const goBack = () => {
        window.history.back();
    };

    useEffect(() => {
        setChild(childData);
    }, [child])

    let age = getAgeLabel(child.dob);

    return (
        <div key={childData.name}>
            <div className="childName">
                <ArrowBackIosIcon onClick={goBack} style={{ fontSize: 20 }} className='child-detailpage-back-icon' /> {child.name} / {child.gender} {age} <span className="mcpNumber">{child.mcpNumber}</span>
                <Link to={"../child/" + child.id + "/edit"}><BorderColorIcon style={{ fontSize: 17, color: 'black' }} /></Link>
            </div>
        </div>
    );
};

export default ChildHeader;
import React, { createRef } from "react";
import uniqid from 'uniqid';

class BaseComponent extends React.Component {    
    constructor(props) {
        super(props);
        this.widgetId = props.widgetId || uniqid("palmyra-");
    }

    getWidgetId(){
        return this.widgetId;
    }

    render() {
        return (
            <div>
            </div>
        );
    }
}
export default BaseComponent;

class RandomGenerator {
    getRandom(baseline, variance) {
        return Math.floor(Math.random() * variance) + baseline;
    }

    getRandomArray(baseline, variance, count) {
        var data = [];
        for (var i = 0; i < count; i++) {
            data[i] = this.getRandom(baseline, variance);
        }
        return data;
    }

    getRandomYArray(baseline, variance, count) {
        var data = [];
        for (var i = 0; i < count; i++) {
            var y = this.getRandom(baseline, variance);
            var p = { x: "", y: y };
            data[i] = p;
        }
        return data;
    }
}

const randomGenerator = new RandomGenerator();

export default randomGenerator;

class RandomBarGenerator {
    getRandom(baseline, variance) {
        baseline = 0;
        variance = 120;
        return Math.floor(Math.random() * variance) + baseline;
    }

    getRandomArray() {
        var data = [];
        for (var i = 0; i < 35; i++) {
            data[i] = this.getRandom(50, 50);
        }
        return data;
    }

    getRandomYArray() {
        var data = [];
        for (var i = 0; i < 35; i++) {
            var y = this.getRandom(50, 50);
            var p = { x: "", y: y };
            data[i] = p;
        }
        return data;
    }
}

const randomBarGenerator = new RandomBarGenerator();

export default randomBarGenerator;

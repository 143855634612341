import { useState, useEffect } from "react";
import PalmyraDataStore from '../../palmyra/chart/store/palmyra/PalmyraDataStore';
import './childDetail.css';

function ChildPediatricVisits(props) {
    const [pediatricVisit, setPediatricVisit] = useState([]);

    useEffect(() => {
        if(props.childData.id){
            const dataStore = new PalmyraDataStore({ target: "child/pediatricVisit" });
            const orderBy = ["-visitedOn"];
            dataStore.query({ criteria: { childId: props.childData.id }, orderBy, limit: 5 }, (d) => {
                if (d.result.length) {
                    setPediatricVisit(d.result);
                }
            })
        }
    }, [props.childData])

    return (
        <div className="pediatricvisit-box">
            <p className="pediatricvisit">Pediatric Visits</p>
            <table>
                <tbody>
                    <tr>
                        <td className="pediatricvisit-itemhead">Date</td>
                        <td className="pediatricvisit-itemhead">Hospital Name</td>
                        <td className="pediatricvisit-itemhead">Pediatrician</td>
                    </tr>
                    {pediatricVisit.map((visit, index) => (
                        <tr key={index}>
                            <td className="pediatricvisit-item">{visit.visitedOn}</td>
                            <td className="pediatricvisit-item">{visit.hospitalName}</td>
                            <td className="pediatricvisit-item">{visit.pediatrician}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ChildPediatricVisits;

import axios from 'axios';

class PalmyraDashboardStore {

    constructor(props) {
        this.target = props.target;
    }
    getData(count, callback) {
        axios.post(this.target, {})
            .then(response => {
                callback(response.data.result);
            })
            .catch(error => console.log(error.response));
    }
}

export default PalmyraDashboardStore;
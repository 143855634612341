import PalmyraLineChart from "../../palmyra/chart/PalmyraLineChart";

class GrowthChartWeight extends PalmyraLineChart {
    constructor(props) {
        super(props);
        let child = props.child || {};
        this.state.child = child;
    }
}

class GrowthChartDecoratorWeight {
    constructor(options) {
        this.options = options;
    }
    decorate(dataset, datasets, index) {
        var borderColors = ["#1111aa66", "#FF000066", "#FEBE0088", "#00FF0088"];
        var config = {
            tension: 0.4,
            fill: false,
            beginAtZero: true,
            pointRadius: 0,
            showLine: true,
            pointHitRadius: 0,
            borderWidth: "1",
            datalabels: { display: false }
        }


        if (index < 7) {
            var i = index < 4 ? index : 6 - index;
            return { ...dataset, ...config, ...{ borderColor: borderColors[i] } };
        }
        else
            return { ...dataset, ...this.options };
    }
    setOptions(options) {
        this.options = options;
    }
}

export default GrowthChartWeight;

export { GrowthChartDecoratorWeight }

const formatBIT = (columnDef) => {
    var pattern = columnDef.displayPattern || [{"1" : "Yes"}, {"0" : "No"}];
    var data = {};
    if(Array.isArray(pattern)){
        for(var idx in pattern){
            data = {...data, ...pattern[idx]};
        }
    }

    return (info) => {
        const value = info.getValue();
        if (value){
            if(data[value])
                return data[value];
        }
        
        if(value)
            return data[1];
        else
            return data[0];
    }
}





const formatters = {
    BIT : formatBIT
}



const getFormatFn = (columnDef) =>{
    const displayFormat = columnDef.displayFormat;
    let methodGen = formatters[displayFormat];
    if(methodGen){
        let formatter = methodGen(columnDef);
        return {cell:formatter};
    }
    else
        return {cell: info => info.getValue()};
}

export {getFormatFn}
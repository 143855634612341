import React, {useContext } from 'react';
import { Drawer, List, Stack, Toolbar, Typography } from "@mui/material";
import sizeConfigs from "../configs/sizeConfigs";
import appRoutes from "../routes/appRoutes";
import { useWindowSize } from "usehooks-ts";
import { MobileContext } from '../context/mobileModeContext';
import '../common/Sidebar.scss';
import TreeMenu from '../../palmyra/widget/layout/TreeMenu';


const Sidebar = () => {

  const { width, height } = useWindowSize();
  const { mobileOpen, setMobileOpen } = useContext(MobileContext);
  const { mobileMode, setMobileMode } = useContext(MobileContext);

  let container = document.body;

  let handleDrawerToggle = function () {
    setMobileOpen(!mobileOpen);
  };

  let responsive = width < 900;
  setMobileMode(responsive);
  let drawerList = <List disablePadding>
    <div className='sidebar'>
    <div className='sidebar-header'>
    <Toolbar sx={{ marginBottom: "20px" }}>
      <Stack
        sx={{ width: "100%" }}
        direction="row"
        position={'relative'}
        justifyContent="center"
      >
        <Typography variant="h6">
          PoshaQ Tracker
        </Typography>
      </Stack>
    </Toolbar>
    </div>
    <div className='sidebar-middle'>
    <TreeMenu  appRoutes={appRoutes}/>
    </div>
    
     <div className='sidebar-footer'>
   <p className='sidebar-footer-text'>Powered by Palmyra</p>
 </div>
 </div>
  </List>;
   

 
  

  if (responsive) {
    return <Drawer
      container={container}
      variant="temporary"
      open={mobileOpen}
      onClose={handleDrawerToggle}
      ModalProps={{
        keepMounted: true
      }}
      sx={{
        display: 'block',
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box', width: sizeConfigs.sidebar.width,
          backgroundColor: "#2A3F54",
          color: "#ffffffef"
        },
      }}
    >
      {drawerList}
    </Drawer>;
  } else
    return (
      
      <Drawer
        variant="permanent"
        sx={{
          width: sizeConfigs.sidebar.width,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: sizeConfigs.sidebar.width,
            boxSizing: "border-box",
            borderRight: "0px",
            backgroundColor: "#2A3F54",
            color: "#ffffffef"
          }
        }}
      >
        
        {drawerList}
       
      </Drawer>
    );
};
export default Sidebar;
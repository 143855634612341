import React from 'react';
import ChildHeader from '../../components/childDetail/ChildHeader';
import ChildImmunization from '../../components/childDetail/ChildImmunization';
import ChildMetrics from '../../components/childDetail/ChildMetrics';
import ChildPediatricVisits from '../../components/childDetail/ChildPediatricvisits';
import ChildRecentActivity from '../../components/childDetail/ChildRecentActivity';
import PalmyraDataStore from '../../palmyra/chart/store/palmyra/PalmyraDataStore';
import ChildGrowthWeightChart from '../../components/childDetail/ChildGrowthWeightChart';
import ChildGrowthHeightChart from '../../components/childDetail/ChildGrowthHeightChart';
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import './ChildDetailPage.scss';

function ChildDetailPage() {

    const [child, setChild] = useState({});
    const { childId } = useParams();

    useEffect(() => {
        const dataStore = new PalmyraDataStore({ target: "child/basic" });
        dataStore.query({ criteria: { id: childId } }, (d) => {
            if (1 === d.result.length) {
                setChild(d.result[0]);
            }
        })
    }, [childId]);

    return (
        <div>
            <div className='childDetailFrame'>
                <div className='childDetail'>
                    <div><ChildHeader childData={child} key={child.id} /></div>
                    <div className='childRecentActivity'><ChildRecentActivity childData={child} key={child.id}/></div>
                    <div className='growthChart'>
                        <div className='growthWeightChart'><ChildGrowthWeightChart childData={child} key={child.id} /></div>
                        <div className='growthHeightChart'><ChildGrowthHeightChart childData={child} key={child.id} /></div>
                    </div>
                </div>
                <div className='childAdditionalDetail'>
                    <div className='childMetrics'><ChildMetrics childData={child} key={child.id} /></div>
                    <div className='childImmunization'><ChildImmunization childData={child} key={child.id} /></div>
                    <div className='childPediatricvisit'><ChildPediatricVisits childData={child} key={child.id} /></div>
                </div>
            </div>
        </div>
    );
}

export default ChildDetailPage;
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Typography, Box } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import sizeConfigs from "../configs/sizeConfigs";
import { MobileContext } from '../context/mobileModeContext';
import Avatar from '@mui/joy/Avatar';
import { useParams } from "react-router-dom";
import PalmyraDataStore from "../../palmyra/chart/store/palmyra/PalmyraDataStore";

import '../common/Topbar.scss';

const drawerWidth = 240;

const Topbar = () => {

  const [profile, setProfile] = useState({});
  const { loginName } = useParams();

  useEffect(() => {
    const dataStore = new PalmyraDataStore({ target: "about/me" });
    dataStore.query({ criteria: { id: loginName } }, (d) => {
      if (1 === d.result.length) {
        setProfile(d.result[0]);
      }
    })
  }, [loginName])

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const goToLogin = useNavigate();

  const logOut = (event) => {
    event.stopPropagation();
    goToLogin("/login");
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    let handler = (event) => {
      if (!event.target.closest(".dropdown-content")) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const { mobileOpen, setMobileOpen } = useContext(MobileContext);
  const { mobileMode, setMobileMode } = useContext(MobileContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  let display = mobileMode ? "block" : "none";

  return (
    <AppBar
      sx={{
        width: { md: `calc(100% - ${sizeConfigs.sidebar.width})` },
        ml: { md: sizeConfigs.sidebar.width },
        backgroundColor: "rgba(115,95,55,1)"
      }}
    >
      <Toolbar>
        <IconButton
          color="default"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 3, display: { display } }}
        >
          <MenuIcon />
        </IconButton>
        <div className='topbar-avathar' onClick={toggleDropdown}>
          <div>
            <Avatar />
          </div>
          <div>
            <p className='avathar-name'>{profile.name}</p>
          </div>
        </div>
        {dropdownOpen && (
          <div className="dropdown-content">
            <ul>
              <li>Profile</li>
              <li>Account settings</li>
              <li onClick={logOut}>Sign out</li>
            </ul>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
import Girls24Month from './GirlGrowthMetric24Month.json';
import Girls60Month from './GirlGrowthMetric60Month.json';
import Girls84MOnth from './GirlGrowthMetric84Month.json';
import Girls120Month from './GirlGrowthMetric120Month.json';
import Boys24Month from './BoyGrowthMetric24Month.json';
import Boys60Month from './BoyGrowthMetric60Month.json';
import Boys84Month from './BoyGrowthMetric84Month.json';
import Boys120Month from './BoyGrowthMetric120Month.json';

class GrowthMetricFactoryWeight {
    constructor(props) {

        this.boysMetric = [
            Boys24Month,
            Boys60Month,
            Boys84Month,
            Boys120Month
        ];

        this.girlsMetric = [
            Girls24Month,
            Girls60Month,
            Girls84MOnth,
            Girls120Month
        ];

        this.ageRange = [2, 5, 7, 10, 13, 18];
    }

    getMetric(child, min, max) {
        switch (child.gender) {
            case 'M':
                return this.joinMetrics(min, max, this.boysMetric);
            case 'F':
                return this.joinMetrics(min, max, this.girlsMetric);
            default:
                return this.joinMetrics(min, max, this.boysMetric);
        }
    }

    joinMetrics(min, max, metrics) {
        let start = 0;
        let end = 1;

        for (let i = 0; i < this.ageRange.length; i++) {
            const age = this.ageRange[i];

            if (age * 12 > min) {
                break;
            }

            start = i;
        }

        for (let i = 0; i < this.ageRange.length; i++) {
            const age = this.ageRange[i];

            if (age * 12 > max) {
                end = i;
                break;
            }
        }

        if (max > 18 * 12) {
            end = this.ageRange.length - 1;
        }

        const size = end + 1;

        let data = [];
        let labels = [];

        for (let i = 0; i <= end & i < metrics.length; i++) {
            labels.push.apply(labels, metrics[i].labels);
            for (let x in metrics[i].data) {
                if (!data[x])
                    data[x] = [];
                data[x].push.apply(data[x], metrics[i].data[x])
            }
        }
        return { labels, data };
    }
}

const growthMetricFactoryWeight = new GrowthMetricFactoryWeight();

export default growthMetricFactoryWeight;
import React from 'react';

import PalmyraGridX from '../../../palmyra/widget/datagrid/PalmyraGridX';

const columnDef = [
    {
        name: "name",
        label: "Name",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
    {
        name: "address",
        label: "Address",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
    {
        name: "type",
        label: "Type",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
    {
        name: "pincode",
        label: "Pincode",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
    {
        name: "tehsilName",
        label: "Tehsil",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
    {
        name: "projectName",
        label: "Project",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    }
];



const HospitalPage = () => {
    return (
        <div>
            Hospital
            <PalmyraGridX columns={columnDef} target="admin/masterdata/hospital" />
        </div>
    )
}
export default HospitalPage;
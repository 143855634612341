import React from 'react';

import PalmyraGridX from '../../../palmyra/widget/datagrid/PalmyraGridX';

const columnDef = [
    {
        name: "name",
        label: "Name",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
    {
        name: "code",
        label: "Code",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
    {
        name: "address",
        label: "Address",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
    {
        name: "pincode",
        label: "Pincode",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
    {
        name: "grampanchayatName",
        label: "Grampanchayat",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
    {
        name: "projectName",
        label: "Project",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
    {
        name: "tehsilName",
        label: "Tehsil",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
    {
        name: "supervisorName",
        label: "Supervisor",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
    {
        name: "healthOfficerName",
        label: "Health Officer",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    }
];


const AnganwadiPage = () => {
    return (
        <div>
            Anganwadi
            <PalmyraGridX columns={columnDef} target="admin/masterdata/anganwadi" />
        </div>
    )
}
export default AnganwadiPage;
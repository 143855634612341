import { useState, useEffect } from "react";
import PalmyraDataStore from '../../palmyra/chart/store/palmyra/PalmyraDataStore';

function ChildImmunization(props) {

    const [immunization, setImmunization] = useState([]);

    useEffect(() => {
        if(props.childData.id){
            const dataStore = new PalmyraDataStore({ target: "child/immunization" });
            const orderBy = ["-doseOn"];
            dataStore.query({ criteria: { childId: props.childData.id }, orderBy, limit: 5 }, (d) => {
                if (d.result.length) {
                    setImmunization(d.result);
                }
            })
        }
    }, [props.childData])

    return (
        <div className="immunizationBox">
            <p className="immunization">Immunization</p>
            <table>
                <tbody>
                    <tr>
                        <td className="immunizationDateLabel">Date</td>
                        <td className="immunizationNameLabel">Immunization</td>
                    </tr>
                    {immunization.map((details, index) => (
                        <tr key={index}>
                            <td className="immunizationDate">{details.doseOn}</td>
                            <td className="immunizationName">{details.immunization}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

};

export default ChildImmunization;
import GrowthChartWeight, { GrowthChartDecoratorWeight } from "../child/GrowthChartWeight";
import PalmyraDataStore from "../../palmyra/chart/store/palmyra/PalmyraDataStore";
import { getAgeLabelByMonth } from "../child/Util";
import { GrowthHistoryWeightConverter } from "../converter/GrowthHistoryConverter";
import { useState } from "react";

function ChildGrowthWeightChart(props) {

    const [child, setChild] = useState(props.childData);

    const orderBy = ["measuredOn"];
    const filter = { criteria: { childId: child.id }, orderBy };
    let dataStore = new PalmyraDataStore({ target: "child/growthHistory", filter });
    var weightConverter = new GrowthHistoryWeightConverter({ child, yName: "weight" });

    var decoratorWeight = new GrowthChartDecoratorWeight({
        borderColor: 'rgba(19,19,15,0.6)',
        spanGaps: true,
        datalabels: { display: false }
    });

    return (
        <div style={{ height: '50vh' }}>
            <GrowthChartWeight options={weightConfig} dataStore={dataStore}
                converter={weightConverter} decorator={decoratorWeight} child={child} />
        </div>
    );
}

export default ChildGrowthWeightChart;

const weightConfig = {
    maintainAspectRatio: false,
    plugins: {
        title: {
            display: true,
            text: "Weight"
        },
        legend: {
            display: false,
            position: "top"
        },
        tooltip: {
            callbacks: {
                title: function (ctx) {
                    let context = ctx[0];
                    return getAgeLabelByMonth(Number(context.label)) + "\n" + context.raw + "kg";
                },
                label: function (context) {
                    return '';
                }
            }
        }
    }, scales: {
        x: {
            type: 'linear',
            beginAtZero: true,
            ticks: {
                mirror: true,
                color: '#aaaaaa',
                maxTicksLimit: 8
            },
            grid: {
                color: "#f9f9f9"
            }
        },
        y: {
            type: 'linear',
            beginAtZero: true,
            ticks: {
                mirror: true,
                color: '#aaaaaa',
                maxTicksLimit: 8
            },
            grid: {
                color: "#f9f9f9"
            }
        }
    }
};
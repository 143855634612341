import axios from 'axios';

class PalmyraDataStore {

    constructor(props) {
        this.target = props.target;
        this.filter = props.filter || {};
        this.baseUrl = '/fluwiz/v2/api/';
    }
    
    getData(count, callback){
        return this.query(this.filter, (d)=> callback(d.result));
    }
    
    query(requestBody, callback, options) {
        var targetUrl = this.baseUrl + "query/" + this.target;
        axios.post(targetUrl, requestBody)
            .then(response => {
                callback(response.data);
            })
            .catch(error => console.log(error));
    }
}

export default PalmyraDataStore;
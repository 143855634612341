import { React, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const ComboBox = (props) => {
  const [value, setValue] = useState(null);
  let options = props.options;
  let placeHolder = props.placeHolder || 'Select';
  const idKey = props.key || 'id';
  const nameKey = props.value || 'name';
  const width = props.width || 300;

  const onChangeCallback = props.onChange;

  const handleValueChange = (event, newValue, reason) => {
    let val = (newValue) ? { id: newValue[idKey], value: newValue[nameKey] } : null;
    setValue(val);
    console.log(val);
    if (onChangeCallback) {
      onChangeCallback(val);
    }
  }

  return (
    <Autocomplete
      disablePortal
      options={options}
      onChange={handleValueChange}
      size="small"
      sx={{ width: width }}
      hiddenlabel="true"
      variant="text"
      renderInput={(params) =>
        <TextField {...params}
          placeholder={placeHolder}
        />}
    />
  );
}

export default ComboBox;
import React from "react";
import NoopsConverter from "./converter/NoopsConverter";
import NoopsDecorator from "./decorator/NoopsDecorator";


class PalmyraChart extends React.Component {
    constructor(props) {
        super(props);
        this.converter = props.converter ? props.converter : new NoopsConverter();
        this.decorator = props.decorator ? props.decorator : new NoopsDecorator();
        this.dataStore = props.dataStore;
        
        let config = props.options || {};
        
        let refreshInterval = config.autoRefresh ? config.autoRefresh : 0;        
        let refreshEnabled = refreshInterval > 0;

        this.state = {
            config: config,
            data: { labels: [], datasets: [] },
            refresh: {interval:refreshInterval, enabled:refreshEnabled}
        }
    }

    fetchData() {
        var _this = this;
        if (this.dataStore) {
            return this.dataStore.getData(18, (rawData) => {
                var pData = this.converter.convert(rawData);
                var data = pData.data || pData.datasets;
                var datasets = [];
                if (data instanceof Array) {
                    for (var idx in data) {
                        var d = data[idx];
                        if (d.data && d.data instanceof Array) {
                            datasets.push(d);
                        }
                    }
                }

                for (var i in datasets) {
                    datasets[i] = this.decorator.decorate(datasets[i], datasets, i);
                }

                var graphData = { labels: pData.labels };
                graphData.datasets = datasets;
                _this.setState({ data: graphData });
            })
        } else
            console.error("Mandatory DataStore not provided");
    }

    componentDidMount() {
        this.fetchData();
        if (this.dataStore) {
            let refreshEnabled = this.state.refresh.enabled;
            let refreshInterval = this.state.refresh.interval;
            if (refreshEnabled && refreshInterval > 0) {
                var _this = this;
                this.scheduler = setInterval(() => {
                    _this.fetchData();
                }, refreshInterval * 1000);
            }
        }
    }

    componentWillUnmount() {
        if (this.scheduler) {
            clearInterval(this.scheduler);
        }
    }

    getChartData() {
        return this.state.data;
    }

    getChartConfig() {
        return this.state.config;
    }

    render() {
        return <div></div>
    }
}

export default PalmyraChart;
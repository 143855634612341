import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { routes } from "./components/routes";
import LoginPage from './pages/LoginPage';
import MainLayout from "./components/layout/MainLayout";
import { MobileModeContextProvider } from "./components/context/mobileModeContext";
import axios from 'axios';

/** set baseUrl = "/" to avoid context changes */
axios.defaults.baseURL = "/";

function App() {
  return (
    <MobileModeContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/login" replace={true} />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/home" element={<MainLayout />}>
            {routes}
          </Route>

        </Routes>
      </BrowserRouter>
    </MobileModeContextProvider>
  );
}

export default App;

import { useState } from "react";
import './childDetail.css';

function ChildMetrics(props) {
    const [child, setChild] = useState(props.childData);

    const getInterventionType = (child) => {
        switch (child.interventionType) {
            case 'S':
                return 'Surgical';
            case 'M':
                return 'Medicine';
            case 'D':
                return 'Diet';
            default:
                return '.....';
        }
    }
    const getStunting = (child) => {
        switch (child.stunting) {
            case 'S':
                return 'Severe';
            case 'M':
                return 'Moderate';
            case 'N':
                return 'Normal';
            default:
                return '.....';
        }
    }
    const getWasting = (child) => {
        switch (child.wasting) {
            case 'S':
                return 'Severe';
            case 'M':
                return 'Moderate';
            case 'N':
                return 'Normal';
            default:
                return '.....';
        }
    }
    const getUnderWeight = (child) => {
        switch (child.underWeight) {
            case 'S':
                return 'Severe';
            case 'M':
                return 'Moderate';
            case 'N':
                return 'Normal';
            default:
                return '.....';
        }
    }

    let intervention = getInterventionType(child);
    let stunting = getStunting(child);
    let wasting = getWasting(child);
    let underWeight = getUnderWeight(child);

    return (
        <div className="metrics-box">
            <p className="metrics">Status Summary</p>
            <table>
                <tbody>
                    <tr>
                        <td><p className="metrics-label">Intervention Type:</p></td>
                        <td><p className="metrics-input">{intervention}</p></td>
                    </tr>
                    <tr>
                        <td><p className="metrics-label">Stunting:</p></td>
                        <td><p className="metrics-input">{stunting}</p></td>
                    </tr>
                    <tr>
                        <td><p className="metrics-label">Wasting:</p></td>
                        <td><p className="metrics-input">{wasting}</p></td>
                    </tr>
                    <tr>
                        <td><p className="metrics-label">Under Weight:</p></td>
                        <td><p className="metrics-input">{underWeight}</p></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );

};

export default ChildMetrics;
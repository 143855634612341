import React from 'react'
import '../../widget/datagrid/Grid.scss';

import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'


import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'


function TableX({columnDefs, rowData}){
  const table = useReactTable({
    data:rowData,
    columns:columnDefs,
    getCoreRowModel: getCoreRowModel(),
  })

  return (    
    <Box sx={{ width: '100%' }}>
    <TableContainer component={Paper} >
      <Table sx={{ minWidth: 650 }} aria-label="simple table" >
        <TableHead className='table-head'>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow key={headerGroup.id}  >
              {headerGroup.headers.map(header => {
                return (
                  <TableCell key={header.id} colSpan={header.colSpan} >
                    {header.isPlaceholder ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}                        
                      </div>
                    )}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody >
          {table.getRowModel().rows.map(row => {
            return (
              <TableRow key={row.id} className='table-row'>
                {row.getVisibleCells().map(cell => {
                  return (
                    <TableCell key={cell.id} >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                      
                    </TableCell>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>    
  </Box>
  )
}

export default TableX;
import React, { createRef, useRef, createElement } from "react";
import PluggableRenderer from "./PluggableRenderer";
import BaseComponent from '../../react/BaseComponent';
import ServerFilter from "./filter/ServerFilter";
import SimpleGrid from "./SimpleGrid";
import { getNextPage, getPrevPage, calcPage } from "./pagination/PaginationUtils";
import('./grid.css')

class ServerGrid extends BaseComponent {
    
    constructor(props) {
        super(props);
        let applyCriteria = (criteria) => this.applyFilter(criteria);
        this.serverFilter = new ServerFilter({applyCriteria});

        let pageData= props.pageData || {currentPage :0, pageSize:16, totalRecords: 0};
        var columnRenderer = props.renderer;
        var _grid = props.gridWidget || PluggableRenderer;
        var gridRenderer = <_grid className={props.className}  renderer={columnRenderer} />; 
        this.rowRenderer = columnRenderer;
        this.gridRenderer = _grid;       

        this.onPageChange = props.onPageChange;
        
        this.className = props.className || "";
        var records =  [];
        this.state = {
            gridRenderer, columnRenderer, pageData, records
        };
    }

    componentDidMount() {
        this.refreshData({limit:this.state.pageData.pageSize, total:true});        
    }

    gotoNextPage(){
        let nextPage = getNextPage(this.state.pageData);        
        if(nextPage){
            this._navigatePage({offset:nextPage.offset, limit:nextPage.limit});
        }
    }

    gotoPage(pageNumber){
        var curPage = this.state.pageData.currentPage-1;
        if(curPage < pageNumber){
            this.gotoNextPage();
        }else if(curPage > pageNumber){
            this.gotoPrevPage();
        }
    }

    gotoPrevPage(){
        let prevPage = getPrevPage(this.state.pageData);
        if(prevPage){
            this._navigatePage({offset:prevPage.offset, limit:prevPage.limit});
        }
    }

    _navigatePage(filter){
        var criteria = this.serverFilter.getCriteria();
        filter.criteria = criteria;
        this.refreshData(filter);
    }

    applyFilter(criteria){        
        var filter = {criteria:criteria, limit:this.state.pageData.pageSize, total:true}
        this.refreshData(filter);
    }

    refreshData(options) {
        let queryString = JSON.stringify(options);
        
        this.getDataStore().query(options, (d) => {
            if(!options.total){
                d.total = this.state.pageData.totalRecords;
            }
            if(options.limit)
                d.limit = options.limit;

            let newState = {pageData:calcPage(d)};
            let records = d.result;  
            if(this.onPageChange) {
                this.onPageChange(newState.pageData);
            }
            this.setState(newState);
            this.setState({ records});             
        });
    }

    applyCriteria(key, criteria){
        this.serverFilter.addCriteria(key, criteria);
    }

    getDataStore(){}

    render() {
        var records = this.state.records;
        var rowRenderer = this.rowRenderer;
        var gridRenderer = this.gridRenderer;
        var className = this.className;

        let grid = createElement(SimpleGrid, {className, rowRenderer, gridRenderer, records});
        
        return (
            <div>
             {grid}
            </div>
        );
    }
}
export default ServerGrid;
import GrowthChartWeight, { GrowthChartDecoratorWeight } from "./GrowthChartWeight";
import GrowthChartHeight, { GrowthChartDecoratorHeight } from "./GrowthChartHeight";
import PalmyraDataStore from "../../palmyra/chart/store/palmyra/PalmyraDataStore";
import { getAgeLabel, getAgeLabelByMonth } from "./Util";
import { GrowthHistoryWeightConverter, GrowthHistoryHeightConverter } from "../converter/GrowthHistoryConverter";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import '../child/ChildCard.scss';

const GrowthChartDialog = (props) => {

    const child = props.child;

    var decoratorWeight = new GrowthChartDecoratorWeight({
        borderColor: 'rgba(19,19,15,0.6)',
        borderWidth: 2,
        spanGaps: true,
        datalabels: { display: false }
    });

    var decoratorHeight = new GrowthChartDecoratorHeight({
        borderColor: 'rgba(19,19,15,0.6)',
        borderWidth: 2,
        spanGaps: true,
        datalabels: { display: false }
    });

    const orderBy = ["measuredOn"];

    const filter = { criteria: { childId: child.id }, orderBy };
    let dataStore = new PalmyraDataStore({ target: "child/growthHistory", filter });
    var weightConverter = new GrowthHistoryWeightConverter({ child, yName: "weight" });
    var heightConverter = new GrowthHistoryHeightConverter({ child, yName: "height" });
    let callback = props.onClose;

    let handleClose = () => {
        callback();
    }

    let age = getAgeLabel(child.dob);

    return (
        <Dialog open={true} onClose={handleClose} fullWidth maxWidth="lg"
            PaperProps={{ sx: { height: "90vh" } }}>
            <DialogTitle>{child.name}/{child.gender} {age} - Growth Chart</DialogTitle>
            <DialogContent style={{ display: "flex" }}>
                <div className='height-weight-chart' >
                    <div className='weight-chart' >
                        <GrowthChartWeight options={weightConfig} dataStore={dataStore}
                            converter={weightConverter} decorator={decoratorWeight} child={child} />
                    </div>
                    <div className='height-chart' >
                        <GrowthChartHeight options={heightConfig} dataStore={dataStore}
                            converter={heightConverter} decorator={decoratorHeight} child={child} />
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}
export default GrowthChartDialog;



const weightConfig = {
    maintainAspectRatio: false,
    plugins: {
        title: {
            display: true,
            text: "Weight"
        },
        legend: {
            display: false,
            position: "top"
        },
        tooltip: {
            callbacks: {
                title: function (ctx) {
                    let context = ctx[0];
                    return getAgeLabelByMonth(Number(context.label)) + "\n" + context.raw + "kg";
                },
                label: function (context) {
                    return '';
                }
            }
        }
    }, scales: {
        x: {
            type: 'linear',
            beginAtZero: true,
            ticks: {
                mirror: true,
                color: '#aaaaaa',
                maxTicksLimit: 8
            },
            grid: {
                color: "#f9f9f9"
            }
        },
        y: {
            type: 'linear',
            beginAtZero: true,
            ticks: {
                mirror: true,
                color: '#aaaaaa',
                maxTicksLimit: 8
            },
            grid: {
                color: "#f9f9f9"
            }
        }
    }
};

const heightConfig = {
    maintainAspectRatio: false,
    plugins: {
        title: {
            display: true,
            text: "Height"
        },
        legend: {
            display: false,
            position: "top"
        },
        tooltip: {
            callbacks: {
                title: function (ctx) {
                    let context = ctx[0];
                    return getAgeLabelByMonth(Number(context.label)) + "\n" + context.raw + "cm";
                },
                label: function (context) {
                    return '';
                }
            }
        }
    }, scales: {
        x: {
            type: 'linear',
            beginAtZero: true,
            ticks: {
                mirror: true,
                color: '#aaaaaa',
                maxTicksLimit: 8
            },
            grid: {
                color: "#f9f9f9"
            }
        },
        y: {
            type: 'linear',
            beginAtZero: true,
            ticks: {
                mirror: true,
                color: '#aaaaaa',
                maxTicksLimit: 8
            },
            grid: {
                color: "#f9f9f9"
            }
        }
    }
};
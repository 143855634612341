import { React, useRef } from "react";
import { AiOutlineSearch } from 'react-icons/ai';
import { BiAbacus } from 'react-icons/bi';
import { debounce } from "../../../common/SysUtils";
import TextField from '@mui/material/TextField';
import { InputAdornment } from "@mui/material";

const SimpleSearch = (options ,props) => {
    const searchInput = useRef(null);

    let doSearch = function (val) {
        if(val){
            let value = val + "*";
            let key = options.key || 'name';
            let filter = {[key]: value};
            if (options.applyFilter)
                options.applyFilter(filter);
        }else{
            options.applyFilter();
        }
    }

    let doDelaySearch = debounce(doSearch, 100);

    let onValueChange = function(event){
        doDelaySearch(event.target.value);
    }

    var advFilter;
    if (options.showFilter) {
        advFilter = <div className="grid-filter-icon grid-icons" onClick={options.showFilter}><BiAbacus /></div>;
    } else {
        advFilter = <div />
    }
    const width = props.width || 200;
    return <div >
        <TextField 
        ref={searchInput} 
        sx={{ width: width }}
        type="text"
        style={{ border: "0px"}}
        onChange={onValueChange}
        size="small"
        placeholder="Name"
        InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                  <AiOutlineSearch className="grid-search-icon grid-icons" />
              </InputAdornment>
            ),
          }} />
        {advFilter}</div>;
}

export default SimpleSearch;
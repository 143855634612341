import generator from "../util/RandomGenerator";

class RandomLineDataStore {
    getData(count, callback) {
        var data = generator.getRandomYArray(40, 16, count);
        callback(data);
    }
}

const lineDataStore = new RandomLineDataStore();

export default lineDataStore;
import LoginHeader from './loginform/LoginHeader';
import LoginInput from './loginform/LoginInput';

const LoginForm=()=>{
    return (
        <div >
            <LoginHeader/>
            <LoginInput/>            
        </div>
    )
}


export default LoginForm;
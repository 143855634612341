import PalmyraDataStore from '../../chart/store/palmyra/PalmyraDataStore';
import Gridx from './Gridx';


function PalmyraGridX(props){
  const {target} = props;
  const store = new PalmyraDataStore({target});

  return (
    <Gridx {...props} store={store}/>
  )
}

export default PalmyraGridX;
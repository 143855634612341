import React, { createRef } from "react";
import ServerGrid from "./ServerGrid";
import PalmyraDataStore from "../../chart/store/palmyra/PalmyraDataStore";

class PalmyraGrid extends ServerGrid {
    
    constructor(props) {
        super(props);
        this.dataStore = new PalmyraDataStore({ target: props.targetUrl });
    }
    
    getDataStore(){
        return this.dataStore;
    }
}
export default PalmyraGrid;
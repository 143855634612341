
import { useNavigate } from 'react-router-dom';
import { DarkModeContext } from '../../context/darkModeContext';
import { useContext, createRef,useEffect } from 'react';
import axios from 'axios';

const LoginInput = () => {

  useEffect(() => {
    username.current.focus();
  }, []);

  const username = createRef();
  const password = createRef();

  const navigate = useNavigate();

  const Login = () => {
    let user = username.current.value;
    let pwd = password.current.value;
    var formData = { "username": user, "password": pwd };
    var loginUrl = 'fluwiz/auth/default/login';
    axios.post(loginUrl, new URLSearchParams(formData))
      .then(function (response) {
        navigate('/home');
      })
      .catch(function (error) {
        alert('Invalid Username or Password')
        console.log(error.response);
      });

  }

  const handleSubmit = (event)=> {
    event.preventDefault();
    Login();
  }

  const { darkMode } = useContext(DarkModeContext);
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div  >
          <input type='text' ref={username} className={darkMode ? "textdark" : 'text'}
            placeholder='Username' name="uname" required></input>
          <br />

          <input type="password" ref={password} className={darkMode ? "passworddark" : 'password'}
            placeholder='Password' name="psw" required></input>
          <br />

          <button onClick={Login} type='submit' className={darkMode ? "loginbtndark login_button" : 'loginbtn login_button'}>Login</button>
        </div>
      </form>
      <div className='copyright'>
        <h5>©2023 PalmyraLabs, all rights reserved</h5>
      </div>
    </div>

  )
}
export default LoginInput;
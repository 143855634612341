import React from 'react';
import PalmyraDashboardStore from '../../palmyra/chart/store/palmyra/PalmyraDashboardStore';
import PalmyraBarChart from '../../palmyra/chart/PalmyraBarChart';
import PointConverter from "../../palmyra/chart/converter/PointConverter"
import SimpleDecorator from "../../palmyra/chart/decorator/SimpleDecorator";
import Chart from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';

class PovertylineChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {}
        };

        const target = 'fluwiz/v2/api/nquery/dashboard/cdpo/byPoverty';
        this.store = new PalmyraDashboardStore({ target });
    }

    componentDidMount() {
        Chart.register(ChartDataLabels);
    }

    render() {
        var config = {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                title: {
                    display: true,
                    text: 'By PovertyLine'
                },
                datalabels: {
                    display: true,
                    color: "gray",
                    formatter: Math.round,
                    anchor: "end",
                    offset: -20,
                    align: "start"
                },
                legend: {
                    display: false
                }
            }
        };

        var decorator = new SimpleDecorator({
            label: 'By PovertyLine',
            backgroundColor: [
                'rgba(255, 99, 132, 0.5)',
                'rgba(255, 159, 64, 0.5)',
                'rgba(153, 102, 255, 0.5)',
                'rgba(201, 203, 207, 0.5)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 0.9)',
                'rgba(255, 159, 64, 0.9)',
                'rgba(153, 102, 255, 0.9)',
                'rgba(201, 203, 207, 0.9)'
            ],
            borderWidth: 2
        });

        var barDataStore = this.store;
        var converter = new PointConverter({ xName: "name", yName: "cnt", defaultLabel: "Not Classified" });

        return (
            <div>
                <PalmyraBarChart style={{ height: '290px', width: '400px' }} options={config} dataStore={barDataStore}
                    converter={converter} decorator={decorator} />
            </div>
        );
    }
}

export default PovertylineChart;




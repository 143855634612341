import React from "react";
import styled from "@emotion/styled";
import { useNavigate } from 'react-router-dom';
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from "@mui/lab/TreeItem";


export default function TreeMenu({ appRoutes }) {
    const navigate = useNavigate();

    const StyledTreeItem = styled(TreeItem)`
    .MuiTreeItem-iconContainer {
        
      }
    `;

    const renderTree = (parent, node, index) => {
        if (node.name) {
            let path = parent ? parent + "/" + node.path : node.path; 
            if(node.children){           
            return (<StyledTreeItem key={index} nodeId={node.name} label={node.name}>
                {Array.isArray(node.children)
                    ? node.children.filter((node) => node.name)
                        .map((childNode, index) => renderTree(path, childNode, index))
                    : null}
            </StyledTreeItem>);
            }else{
                return (<StyledTreeItem key={index} nodeId={node.name} label={node.name} 
                    onClick={(x, y, z) => { navigate(path); }}/>);
            }
        }
    };

    const renderMenu = (appRoutes) => {
        return appRoutes.filter((node) => node.name)
            .map((route, index) => (renderTree(null, route, index)));
    }

    const menu = renderMenu(appRoutes);
    return (

        <TreeView
            aria-label="rich object"
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpanded={['root']}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{ height: "70vh", flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
        >
            {menu}
        </TreeView>
    );
}

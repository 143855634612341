import bubbleGenerator from "../util/RandomBubbleGenerator";

class RandomBubbleDataStore {
    getData(count, callback) {
        var data = bubbleGenerator.getRandomYArray(0, 1204, count);
        callback(data);
    }
}

const bubbleDataStore = new RandomBubbleDataStore();

export default bubbleDataStore;
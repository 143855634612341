import React from 'react';

import PalmyraGridX from '../../../palmyra/widget/datagrid/PalmyraGridX';

const columnDef = [
    {
        name: "blockName",
        label: "Name",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
    {
        name: "blockCode",
        label: "Block Code",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
    {
        name: "tehsilName",
        label: "Taluk",
        searchable: true,
        sortable: true,
        tabDisplay: false,
        quickFilter: false
    },
];


const BlockPage = () => {
    return (
        <div>
            Block
            <PalmyraGridX columns={columnDef} target="admin/masterdata/block" />
        </div>
    )
}
export default BlockPage;
import React from "react";
import { Line } from "react-chartjs-2";
import PalmyraChart from "./PalmyraChart";


class PalmyraLineChart extends PalmyraChart {
    constructor(props) {
        super(props);
        if (props)
            this.style = props.style;
    }

    render() {
        var chartData = this.getChartData();
        var config = this.getChartConfig();

        if (this.style)
            return <Line data={chartData} options={config} style={this.style} />;
        else
            return <Line data={chartData} options={config} />;

    }
}

export default PalmyraLineChart;
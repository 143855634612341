import React, { useState, useEffect } from 'react';
import ChildDetailCommentPopup from './ChildDetailCommentPopup';
import PalmyraDataStore from '../../palmyra/chart/store/palmyra/PalmyraDataStore';
import "./childDetail.css";
import Button from '@mui/material/Button';

const ChildRecentActivity = (props) => {
    const [childNotes, setChildNotes] = useState([]);
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if(props.childData.id){
            const dataStore = new PalmyraDataStore({ target: "child/notes" });
            const orderBy = ["-createdOn"];
            dataStore.query({ criteria: { childId: props.childData.id }, orderBy, limit: 5 }, (d) => {
                if (d.result.length) {
                    console.log(d.result);
                    setChildNotes(d.result);
                }
            })
        }
    }, [props.childData])


    

    const handleButtonClick = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <div style={{ width: '100%', textAlign: 'right' }}>
            <Button className='comment-button'
                style={{ backgroundColor: 'rgba(56, 149, 131, 0.9)', color: 'white' }}
                onClick={handleButtonClick}
            > Add Remarks
            </Button>
            <ChildDetailCommentPopup isOpen={showPopup} onClose={handleClosePopup} />

            <table>
                <tbody>
                    <tr>
                        <td className="immunizationDateLabel">Date</td>
                        <td className="immunizationNameLabel">Remarks</td>
                    </tr>
                    {childNotes.map((notes, index) => (
                        <tr key={index}>
                            <td className="immunizationDate">{notes.createdOn}</td>
                            <td className="immunizationName">{notes.remarks}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ChildRecentActivity;

import React from "react";
import PalmyraLineChart from "../../palmyra/chart/PalmyraLineChart";
import { DarkModeContext } from '../context/darkModeContext';
import lineDataStore from "../../palmyra/chart/store/RandomLineDataStore"
import LineChartConverter from "../../palmyra/chart/converter/LineChartConverter"
import SimpleDecorator from "../../palmyra/chart/decorator/SimpleDecorator";

const config = {
    autoRefresh: 4,
    maintainAspectRatio: false,
    responsive: true,
    animation: {
        duration: 4000
    },
    animations: {
        tension: {
            duration: 500,
            easing: 'linear',
            from: 0.7,
            to: 0.3,
            loop: true
        }
    },
    scales: {
        x: {
            beginAtZero: true,
            display: false,
        },
        y: {
            display: false,
            beginAtZero: true,
            ticks: {
                beginAtZero: true
            }
        }
    },
    plugins: {
        tooltip: {
            enabled: false
        },
        datalabels: {
            display: false
        },
        legend: {
            display: false
        }
    },
    elements: {
        point: {
            radius: 0
        }
    }
};

class LoginLineChart extends React.Component {
    static contextType = DarkModeContext;

    render() {
        const darkMode = this.context.darkMode;
        var bgColor = darkMode ? 'rgba(15,14,14,0.075)' : 'rgba(56, 49, 131, 0.02)';
        var borderColor = darkMode ? 'rgba(6, 9, 31, 0.1)' : 'rgba(56, 49, 131, 0.01)';

        var decorator = new SimpleDecorator({
            label: '',
            beginAtZero: true,
            fill: true,
            lineTension: 0.5,
            backgroundColor: bgColor,
            borderColor: borderColor,
            opacity: 0.01,
            borderWidth: 2
        });

        var converter = new LineChartConverter({ yName: "y" });

        return (
            <div>
                <PalmyraLineChart options={config} dataStore={lineDataStore}
                    converter={converter} decorator={decorator} />
            </div>
        );
    }
}

export default LoginLineChart;
import React from "react";
import { Bubble } from "react-chartjs-2";
import PalmyraChart from "./PalmyraChart";


class PalmyraBubbleChart extends PalmyraChart {

    constructor(props) {
        super(props);
        if (props)
            this.style = props.style;
    }

    render() {
        var chartData = this.getChartData();
        var config = this.getChartConfig();

        if (this.style)
            return <Bubble data={chartData} options={config} style={this.style} />;
        else
            return <Bubble data={chartData} options={config} />;

    }
}

export default PalmyraBubbleChart;
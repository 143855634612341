

class SimpleDecorator {
    constructor(options) {
        this.options = options;
    }
    decorate(dataset, datasets, index) {
        return { ...dataset, ...this.options };
    }
    setOptions(options){
        this.options = options;
    }
}
export default SimpleDecorator;